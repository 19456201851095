import { Program, Provider, web3 } from '@coral-xyz/anchor'
// import { IDL, VoterStakeRegistry } from './voter_stake_registry'
import { IDL, MplStaking } from './voter_stake_registry_with_rewards'

export const DEFAULT_VSR_ID = new web3.PublicKey(
  'C62KFt3Ujv4GmgaNRpTzyvkERVW2jPSje7cGaw4L5hAx'
)
export const DEFAULT_REWARDS_ID = new web3.PublicKey(
  'DdAfv8RS2BS41FRjDX5nLXSmQWrPsdC17sbgD66oKcU8'
)
export const REWARD_POOL_SPACE = 64480

export class VsrClient {
  constructor(
    // public program: Program<VoterStakeRegistry>,
    public program: Program<MplStaking>,
    public devnet?: boolean
  ) {}

  static async connect(
    provider: Provider,
    programId: web3.PublicKey = DEFAULT_VSR_ID,
    devnet?: boolean
  ): Promise<VsrClient> {
    const idl = IDL

    return new VsrClient(
      // new Program<VoterStakeRegistry>(
      new Program<MplStaking>(
        // idl as VoterStakeRegistry,
        idl as MplStaking,
        programId,
        provider
        // ) as Program<VoterStakeRegistry>,
      ) as Program<MplStaking>,
      devnet
    )
  }
}
